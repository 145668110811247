import './styles.css';
import HomeForm from './../form/index.js';

const CompanyContent = () => {
  
  return (
    <>
    <div className='Home_bottom'>
      <HomeForm />
      <div className='Home_bottom_company'>
        <div className='Home_bottom_company_info'>
          <h4>会社概要</h4>
          <div>
            <h4>会社名</h4>
            <p>株式会社ウェルドクラフト社</p>
          </div>
          <div>
            <h4>創業</h4>
            <p>1965年</p>
          </div>
          <div>
            <h4>事業内容</h4>
            <p>ビニール製品の製造・販売</p>
          </div>
          <div>
            <h4>所在地</h4>
            {/* <p>東京都荒川区西日暮里</p> */}
            <p>東京都荒川区西日暮里5-31-10<br />ワイメア・ヴァレー1階</p>
          </div>
        </div>
        <h4>加工機材</h4>
        <div className='Home_bottom_company_photo'></div>
      </div>
    </div>
    </>
  );
}

export default CompanyContent;
