import './styles.css';
import Service1 from './../../image/service1.png';
import Service2 from './../../image/service2.png';
import Service3 from './../../image/service3.png';
import Service4 from './../../image/service4.png';
import Service5 from './../../image/service5.png';
import Service6 from './../../image/service6.png';

const HomeServiceContent = () => {
  
  return (
    <>
    <div className='Home_service_content'>
      <div>
        <img src={Service1} alt='' />
        <h4>名札</h4>
        <p>学童用ビニール名札やビジネス用名札など、用途に合わせて形やサイズなど柔軟なオーダーに対応できます。</p>
      </div>
      <div>
        <img src={Service2} alt='' />
        <h4>カバー</h4>
        <p>手帳カバーやブックカバーを始め、ファイルカバーやキャンプ小物カバーまで、形やサイズに合わせたオーダーに対応できます。</p>
      </div>
      <div>
        <img src={Service3} alt='' />
        <h4>バッグ</h4>
        <p>アパレルバッグや廃棄用袋など、用途に合わせて形やサイズをオーダーでき、柔軟に対応させて頂きます。</p>
      </div>
      <div>
        <img src={Service4} alt='' />
        <h4>ケース</h4>
        <p>学童用ビニール名札やビジネス用名札など、用途に合わせて形やサイズなど柔軟なオーダーに対応できます。</p>
      </div>
      <div>
        <img src={Service5} alt='' />
        <h4>ファイルカバー</h4>
        <p>手帳カバーやブックカバーを始め、ファイルカバーやキャンプ小物カバーまで、形やサイズに合わせたオーダーに対応できます。</p>
      </div>
      <div>
        <img src={Service6} alt='' />
        <h4>オリジナル</h4>
        <p>アパレルバッグや廃棄用袋など、用途に合わせて形やサイズなど柔軟なオーダーに対応できます。</p>
      </div>
    </div>
    </>
  );
}

export default HomeServiceContent;

