import './styles.css';
import { useState } from 'react';
import firebase from "firebase";
import send from './../../image/send.png';

const SubsidyContact = () => {
  
  const [ lastName, setLastName ] = useState('');
  const [ firstName, setFirstName ] = useState('');
  const [ mailAddress, setMailAddress ] = useState('');
  const [ company, setCompany ] = useState('');
  const [ number, setNumber ] = useState('');
  const [ content, setContent ] = useState('');
  const [ status, setStatus ] = useState(false);

  function update(){
    if (lastName !== '' && firstName !== '' && mailAddress !== '' && number !== '' && content !== '') {
      firebase.firestore().collection('mail').doc()
      .set({
        lastName : lastName,
        firstName : firstName,
        mailAddress : mailAddress,
        company : company,
        number : number,
        content : content,
        user_time: new Date(),
      }).then(() => {
        setStatus(true);
      })
    }
  }

  return (
    <>
    {
      status ?
      <div className='send_mail'>
        <img  src={send} alt='' />
        <h6>
          お問い合わせありがとうございます。<br />
          メール送信が完了しました。
        </h6>
      </div>
      :
      <div className='SH_contact'>
        <h4>ご相談/お見積り</h4>
        <div className='SH_contact_title_name'>
          <div>
            <h5>姓</h5>
            <div style={firstName === '' ? {backgroundColor : '#FDD09B'} : {backgroundColor : '#EFEFEF'}}><h5>必須</h5></div>
          </div>
          <div>
            <h5>名</h5>
            <div style={lastName === '' ? {backgroundColor : '#FDD09B'} : {backgroundColor : '#EFEFEF'}}><h5>必須</h5></div>
          </div>
        </div>
        <div className='SH_contact_input_name'>
          <input placeholder='田中' onChange={e => setFirstName(e.target.value)} />
          <input placeholder='太郎' onChange={e => setLastName(e.target.value)} />
        </div>
        <div className='SH_contact_title_company'>
          <h5>会社名</h5>
          <div style={company === '' ? {backgroundColor : '#FDD09B'} : {backgroundColor : '#EFEFEF'}}><h5>必須</h5></div>
        </div>
        <div className='SH_contact_input_company'>
          <input placeholder='株式会社ウェルドクラフト社' onChange={e => setCompany(e.target.value)} />
        </div>
        <div className='SH_contact_title_company'>
          <h5>電話番号</h5>
          <div style={number === '' ? {backgroundColor : '#FDD09B'} : {backgroundColor : '#EFEFEF'}}><h5>必須</h5></div>
        </div>
        <div className='SH_contact_input_company'>
          <input placeholder='080-xxxx-xxxx' onChange={e => setNumber(e.target.value)} />
        </div>
        <div className='SH_contact_title_company'>
          <h5>メールアドレス</h5>
          <div style={mailAddress === '' ? {backgroundColor : '#FDD09B'} : {backgroundColor : '#EFEFEF'}}><h5>必須</h5></div>
        </div>
        <div className='SH_contact_input_company'>
          <input placeholder='info@weldcraft.co.jp' onChange={e => setMailAddress(e.target.value)} />
        </div>
        <div className='SH_contact_title_company'>
          <h5>ご相談内容</h5>
          <div style={content === '' ? {backgroundColor : '#FDD09B'} : {backgroundColor : '#EFEFEF'}}><h5>必須</h5></div>
        </div>
        <div className='SH_contact_inpute_text'>
          <textarea placeholder='名札のご相談したく、ご連絡しました。' onChange={e => setContent(e.target.value)} />
        </div>
        <button onClick={update} style={status ? {backgroundColor : '#888888'} : {backgroundColor : '#CF8329'}}>{status ? '送信完了' : '送信'}</button>
      </div>
    }
    </>
  );
}

export default SubsidyContact;
