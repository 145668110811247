import './styles.css';
import { FiRepeat } from "react-icons/fi"
import { BsBagFill } from "react-icons/bs"
import { BsArrowRepeat } from "react-icons/bs"
import { BiTimeFive } from "react-icons/bi"

const HomeHeader = () => {
  
  return (
    <>
    <div className='header_image_top'>
      <div className='header_image'></div>
      <h4>技術×発想で<br />カタチをつくる</h4>
      <p>創業から50年以上、お客様のご要望をカタチにするための<br />知識・技術力・発想力を磨き続けてきました。<br />ビニール加工において、洗練されたノウハウを確立しています。</p>
      <div className='header_bottom'>
        <div>
          <div><BsBagFill className='header_bottom_icons' /></div>
          <h4>取扱製品：300種類以上</h4>
        </div>
        <div>
          <div><BsArrowRepeat  className='header_bottom_icons' /></div>
          <h4>リピート率：95％</h4>
        </div>
        <div>
          <div><BiTimeFive  className='header_bottom_icons' /></div>
          <h4>創業：50年以上</h4>
        </div>
      </div>
    </div>
    {/* <div className='header'>
      <div className='header_text'>
        <h3>技術×発想でカタチをつくる</h3>
        <h2>Craft & Creative</h2>
        <div className='header_box'>
          <h1>ビニール加工のプロフェッショナル企業</h1>
          <h2>
            私たちウェルドクラフトは、都内に工場を構えるビニール加工のプロフェッショナル企業です。<br />
            創業から50年以上、お客様のご要望をカタチにするための知識・技術力・発想力を磨き続けてきました。
            <span>手作での熟練された技能を求められるビニール加工において、洗練されたノウハウを確立しています。</span>
          </h2>
        </div>
        <div className='header_point'>
          <div><h3>取扱製品：300種類以上</h3></div>
          <div><h3>リピート率：95％</h3></div>
          <div><h3>創業：50年以上</h3></div>
        </div>
      </div>
      <div className='circle_1'></div>
      <div className='circle_2'></div>
      <div className='circle_3'></div>
      <div className='circle_4'></div>
    </div> */}
    </>
  );
}

export default HomeHeader;
