import './styles.css';
import Icon from './../../image/logo.png';

const Nav = () => {
  
  return (
    <>
    <div className='nav'>
      <div className="nav_icon">
        <img src={Icon} alt='ウェルドクラフト' />
        <div>
          <h5>Weld Craft</h5>
          <p>ウェルドクラフト社</p>
        </div>
      </div>
      <div className="nav_text">
        <a href="#area-1"><p>サービス</p></a>
        <a href="#area-2"><p>受注の流れ</p></a>
        <a href="#area-3"><p>Q{"&"}A</p></a>
        <a href="#area-4"><p className='nav_service'>会社概要</p></a>
        <a href="#area-4"><button>問い合わせ</button></a>
      </div>
    </div>
    </>
  );
}

export default Nav;
