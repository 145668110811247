import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDZhJgTHdoGkqdgYx6ywpiMlmU5paZyY_E",
  authDomain: "craft-company.firebaseapp.com",
  projectId: "craft-company",
  storageBucket: "craft-company.appspot.com",
  messagingSenderId: "88110754042",
  appId: "1:88110754042:web:f47422f1b34fc61d568143",
  measurementId: "G-688W7ZS00F"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const storage = firebase.storage();

ReactDOM.render(
  <>
  <App />
  </>,
  document.getElementById('root')
);

reportWebVitals();

export { storage, firebase as default };


