import './styles.css';

const SubsidyProblem = () => {
  
  return (
    <>
    <div className='SQ'>
      <div>
        <div>
          <div>Q</div>
          <h4>納期はどれぐらいかかりますか？</h4>
        </div>
        <p>
          数量や寸法、その他製品仕様によって異なります。ご希望の納期がございましたらお伝え下さい。<br />
          金型や素材がお決まりの場合は数週間〜2ヶ月程で納品できるケースが多いです。<br />
          お急ぎの際は事前に生産スケジュールをご相談・ご検討頂いております。<br />
        </p>
      </div>
      <div>
        <div>
          <div>Q</div>
          <h4>最低ロットはいくつからですか？</h4>
        </div>
        <p>
          極端に申し上げますと1つからでも生産は可能です。<br />
          しかし小ロットの場合は素材仕入れの影響により、価格が高くなる場合がございます。<br />
        </p>
      </div>
      <div>
        <div>
          <div>Q</div>
          <h4>加工以外にも、袋入れ等の内職作業にも対応してもらえますか？</h4>
        </div>
        <p>
          梱包・袋入れ・セット作業も対応可能です。 <br />
          自社工場内に十分なスペースがあり、常時内職作業員も確保しておりますので問題ありません。<br />
        </p>
      </div>
      <div>
        <div>
          <div>Q</div>
          <h4>試作はしていただけますか？</h4>
        </div>
        <p>
          可能です。費用についてはお気軽にご相談ください。<br />
          自社工場内の素材のみで試作できそうなものに関しては無償でできるケースもあります。<br />
        </p>
      </div>
      <div>
        <div>
          <div>Q</div>
          <h4>非フタル酸PVCやオレフィンの取り扱いや加工は出来ますか？</h4>
        </div>
        <p>
          可能です。ご要望に応じた素材を使用して加工を行います。<br />
          非フタル酸PVC、ポリオレフィンの取り扱い、加工も出来ます。<br />
        </p>
      </div>
    </div>
    </>
  );
}

export default SubsidyProblem;
