import './styles.css';
import { FiMail } from "react-icons/fi"
import { AiFillWechat } from "react-icons/ai"
import { CgFileDocument } from "react-icons/cg"
import { BiRun } from "react-icons/bi"
import { FaCheck } from "react-icons/fa"

const ServiceFlow = () => {
  
  return (
    <>
    <div className='home_flow_content'>
      <div className='home_flow_content_box'>
        <h5>01</h5>
        <div className='home_flow_content_border'></div>
        <div className='home_flow_content_iconc_box'>
          <FiMail className='home_flow_icon' />
        </div>
        <div className='home_flow_content_title'>
          <div></div>
          <h4>お問い合わせ</h4>
          <div></div>
        </div>
        <div className='home_flow_content_desc'>
          <FaCheck className='home_flow_content_desc_check' />
          <p>無料で相談</p>
        </div>
        <div className='home_flow_content_desc'>
          <FaCheck className='home_flow_content_desc_check' />
          <p>メール・電話対応</p>
        </div>
        <div className='home_flow_content_desc'>
          <FaCheck className='home_flow_content_desc_check' />
          <p>即日対応</p>
        </div>
      </div>
      <div className='home_flow_content_box'>
        <h5>02</h5>
        <div className='home_flow_content_border'></div>
        <div className='home_flow_content_iconc_box'>
          <AiFillWechat className='home_flow_icon' />
        </div>
        <div className='home_flow_content_title'>
          <div></div>
          <h4>面談/相談</h4>
          <div></div>
        </div>
        <div className='home_flow_content_desc'>
          <FaCheck className='home_flow_content_desc_check' />
          <p>要件ヒアリング</p>
        </div>
        <div className='home_flow_content_desc'>
          <FaCheck className='home_flow_content_desc_check' />
          <p>お見積り作成</p>
        </div>
        <div className='home_flow_content_desc'>
          <FaCheck className='home_flow_content_desc_check' />
          <p>サンプル作成</p>
        </div>
      </div>
      <div className='home_flow_content_box'>
        <h5>03</h5>
        <div className='home_flow_content_border'></div>
        <div className='home_flow_content_iconc_box'>
          <CgFileDocument className='home_flow_icon' />
        </div>
        <div className='home_flow_content_title'>
          <div></div>
          <h4>制作開始</h4>
          <div></div>
        </div>
        <div className='home_flow_content_desc'>
          <FaCheck className='home_flow_content_desc_check' />
          <p>要件決定</p>
        </div>
        <div className='home_flow_content_desc'>
          <FaCheck className='home_flow_content_desc_check' />
          <p>見本製品の作成</p>
        </div>
        <div className='home_flow_content_desc'>
          <FaCheck className='home_flow_content_desc_check' />
          <p>量産体制構築</p>
        </div>
      </div>
      <div className='home_flow_content_box'>
        <h5>04</h5>
        <div className='home_flow_content_border'></div>
        <div className='home_flow_content_iconc_box'>
          <BiRun className='home_flow_icon' />
        </div>
        <div className='home_flow_content_title'>
          <div></div>
          <h4>確認/受注</h4>
          <div></div>
        </div>
        <div className='home_flow_content_desc'>
          <FaCheck className='home_flow_content_desc_check' />
          <p>工程改善</p>
        </div>
        <div className='home_flow_content_desc'>
          <FaCheck className='home_flow_content_desc_check' />
          <p>検査・検品</p>
        </div>
        <div className='home_flow_content_desc'>
          <FaCheck className='home_flow_content_desc_check' />
          <p>追加相談対応</p>
        </div>
      </div>
    </div>
    </>
  );
}

export default ServiceFlow;

