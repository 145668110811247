import './styles.css';
import Total from './../../image/total.png';
import After from './../../image/after.png';

const Point = () => {
  
  return (
    <>
    <div className='Home_point_title'>
      <h5>サポート体制</h5>
    </div>
    <div className='home_point_1'>
      <img src={Total} className="home_point_icon" alt='' />
      <div>
        <h4>トータルサポート</h4>
        <p>ビニール製品の企画から納品までを一貫して対応。お客様の思いをより早く形にするため、企画のご相談・ご提案から設計・金型作成・印刷・加工・納品までトータルでサポートします。</p>
      </div>
    </div>
    <div className='home_point_1'>
      <img src={After} className="home_point_icon" alt='' />
      <div>
        <h4>アフターフォロー</h4>
        <p>受注担当者がお客様の発注内容をデータ管理しています。小ロットの追加発注、急な追加発注、完成品の先上げ出荷や分納など、形ある商品ならではの不測事態にも柔軟に対応します。</p>
      </div>
    </div>
    </>
  );
}

export default Point;

// Q&A
// 問い合わせ
// 実績
// https://ooba.info/

