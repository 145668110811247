import DocumentMeta from 'react-document-meta';
import './styles.css';
import HomeNav from './nav/index.js';
import HomeHeader from './header/index.js';
import HomeService from './service/index.js';
import HomeFlow from './flow/index.js';
import HomePoint from './point/index.js';
import HomeQuestion from './question/index.js';
import HomeCompanyContent from './company/index.js';

const Home = () => {

  const meta = {
    title: '株式会社ウェルドクラフト社',
    description: '私たちウェルドクラフトは、都内に工場を構えるビニール加工のプロフェッショナル企業です。創業から50年以上、お客様のご要望をカタチにするための知識・技術力・発想力を磨き続けてきました。',
    canonical: 'https://weldcraft.co.jp/',
    meta: {charset: 'utf-8',}
  };
  
  return (
    <>
    <DocumentMeta {...meta}>
      <HomeNav />
      <HomeHeader />
      <div id="area-1"></div>
      <div className='Home_title'>
        <h5>サービス</h5>
        <h4>Service</h4>
      </div>
      <HomeService />
      <div id="area-2"></div>
      <div className='flow_point'>
        <div className='Home_title'>
          <h5>受託までの流れ</h5>
          <h4>Service Flow</h4>
        </div>
        <HomeFlow />
        <HomePoint />
        <div id="area-3"></div>
      </div>
      <div className='SB_bottom'>よくあるご質問</div>
      <HomeQuestion />
      <div id="area-4"></div>
      <HomeCompanyContent />
      <div className='home-header-space'>
        <h4>craft-company.com</h4>
      </div>   
    </DocumentMeta>
    </>
  );
}

export default Home;
