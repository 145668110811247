import './App.css';
import React, { BrowserRouter as Router, Route, } from 'react-router-dom';
import Home from './compornent/home/index.js';

function App() {

  return (
    <Router>
      <div className="App">
        <Route exact path="/" component={Home} />
      </div>
    </Router>
  );
}

export default App;


